<template>
  <AdminBulkCheckInOutForm
    title="Check-In"
    description="Select reservations on table below to perform check-ins on those requiring no changes. If changes are needed, then check-in that reservation via the reservation detail facility. To check-in multiple reservations at once use the checkboxes with the bulk check-in button."
    individualAction="CHECK IN"
    bulkAction="BULK CHECK IN"
    path="reservation-check-in"
    sortBy="arrivalDate"
    :parentLoadingSearch="loadingSearch"
    :parentReservations="reservations"
    @search="search"
    @bulkAction="bulkCheckIn"
  />
</template>

<script>
import AdminBulkCheckInOutForm from "@/components/admin/AdminBulkCheckInOutForm.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import moment from "moment";
export default {
  name: "AdminBulkCheckIn",
  title: "Admin - Bulk Check-In",
  components: {
    AdminBulkCheckInOutForm
  },
  props: {
    locationId: String
  },
  data() {
    return {
      loadingSearch: false,
      reservations: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    async search(searchCriteria) {
      this.loadingSearch = true;
      const service = new AdminSpotReservationService(this.tenantId);
      await service
        .searchCheckIn(this.locationId, searchCriteria)
        .then(response => {
          if (response.statusCode === "Success") {
            this.reservations = response.data;
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong",
              layer: "admin"
            });
          }
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    },
    async bulkCheckIn(reservationIds) {
      this.$store.commit("auth/setLoading", true);
      const service = new AdminSpotReservationService(this.tenantId);
      await service
        .bulkCheckIn({
          checkedInOutDate: moment().format("L"),
          reservationIds: reservationIds
        })
        .then(response => {
          if (response.statusCode === "Success" && response.data === true) {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-success",
              message: "Reservations checked-in successfully",
              layer: "admin"
            });
            this.$store.commit("setCheckSearchParams", {});
            this.search({});
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message:
                "Some or all of your reservations may not have been checked in. Please try again.",
              layer: "admin"
            });
          }
        })
        .finally(() => {
          this.$store.commit("auth/setLoading", false);
        });
    }
  },
  created() {
    const searchParams = this.$store.getters["admin/checkSearchParams"];
    this.search(searchParams);
  }
};
</script>
